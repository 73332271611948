<template>
  <app-card-item
    class="my-4 p-4"
  >
    <h2
      class="font-bold"
    >
      {{ l10nTitle(resource) }}
    </h2>

    <p class="text-xs italic">
      {{ t('ttmt.changelogs.show.version') }}
      {{ resource?.attributes.version }}
    </p>

    <!-- eslint-disable vue/no-v-html -->
    <div
      class="text-justify my-3"
      v-html="l10nContent(resource)"
    />

    <p class="text-xs font-semibold">
      {{ resource?.attributes.published_ago }}
    </p>
  </app-card-item>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import useChangelog from '@shared/hooks/resources/changelog'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'

defineProps({
  // JSON API resource used
  // to populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const { t } = useI18n()
const {
  l10nTitle,
  l10nContent,
} = useChangelog()
</script>
