import { useI18n } from 'vue-i18n'

import useBreadcrumb from '@shared/hooks/breadcrumbs'

export default function breadcrumbChangelog() {
  const { t } = useI18n()
  const { forceLink } = useBreadcrumb()

  /**
   * Breadcrumb for index page
   *
   * @returns {[{link: boolean, text: string, to: string}]}
   */
  function index() {
    return [
      {
        text: t('ttmt.breadcrumbs.changelogs.changelogs'),
        link: false,
        to: '/changelogs',
      },
    ]
  }

  /**
   * Breadcrumb for show page
   *
   * @param changelog
   * @returns {*|{link: boolean, text: string, to: string}[]}
   */
  function show(changelog) {
    let breadcrumbs = index()
    breadcrumbs = forceLink(breadcrumbs)

    breadcrumbs.push(
      {
        text: changelog.title ?? changelog.id,
        link: false,
        to: `/changelogs/${changelog.id}`,
      },
    )

    return breadcrumbs
  }

  return {
    index,
    show,
  }
}
