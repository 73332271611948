<template>
  <teleport-wrapper
    v-if="changelog && !loading"
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>

  <changelog-show-card
    v-if="!loading"
    :resource="changelog"
  />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import { fetchChangelog as fetchApiChangelog } from '@shared/http/api'
import breadcrumbChangelog from '@extranet/breadcrumbs/changelog'
import useChangelog from '@shared/hooks/resources/changelog'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import ChangelogShowCard from '@extranet/components/resources/changelog/ChangelogShowCard.vue'

// ---------- RESERVATION ----------

const route = useRoute()

const {
  l10nTitle,
} = useChangelog()

const changelog = ref(null)
const loading = ref(true)

function fetchChangelog() {
  loading.value = true
  const { id } = route.params

  fetchApiChangelog(id)
    .then((response) => {
      changelog.value = response.data.data
    })
    .finally(() => {
      loading.value = false
    })
}

// ---------- BREADCRUMBS ----------

const { show } = breadcrumbChangelog()

const breadcrumbs = computed(() => (
  show({
    id: changelog.value?.id,
    title: l10nTitle(changelog.value),
  })
))

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchChangelog()
})
</script>
