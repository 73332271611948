<template>
  <teleport-wrapper
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>

  <teleport-wrapper to="#mainTitle">
    {{ t('ttmt.breadcrumbs.changelogs.changelogs') }}
  </teleport-wrapper>

  <div
    v-if="!store.state.spinner.active && changelogs.length > 0"
    class="grid grid-cols-1 lg:grid-cols-2 lg:gap-4"
  >
    <div>
      <app-section-title
        :title="t('ttmt.changelogs.index.sections.last')"
      />

      <changelog-show-card
        :resource="lastChangelog"
      />
    </div>

    <div>
      <app-section-title
        :title="t('ttmt.changelogs.index.sections.all')"
      />

      <app-card-item
        v-for="changelog in changelogs"
        :key="changelog.resource.id"
        class="mt-4 p-4"
      >
        <router-link
          :to="{ name: 'show-changelogs', params: { id: changelog.resource.id } }"
          class="block"
        >
          {{ l10nTitle(changelog.resource) }}
        </router-link>

        <p class="text-xs italic">
          {{ t('ttmt.changelogs.show.version') }}
          {{ changelog?.resource.attributes.version }}
        </p>

        <p class="text-xs font-semibold mt-4">
          {{ changelog?.resource.attributes.published_ago }}
        </p>
      </app-card-item>

      <app-pagination
        :links="links"
        :change-page-callback="handleChangePage"
        class="mt-5"
      />
    </div>
  </div>

  <div
    v-else
  >
    {{ t('ttmt.common.messages.no_records') }}
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import {
  fetchChangelogs as fetchApiChangelogs,
  fetchChangelog as fetchApiChangelog,
} from '@shared/http/api'
import breadcrumbChangelog from '@extranet/breadcrumbs/changelog'
import useChangelog from '@shared/hooks/resources/changelog'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import AppPagination from '@extranet/components/ui/AppPagination.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppSectionTitle from '@extranet/components/ui/AppSectionTitle.vue'
import ChangelogShowCard from '@extranet/components/resources/changelog/ChangelogShowCard.vue'

const store = useStore()
const { t } = useI18n()

// ---------- CHANGELOGS ----------

const {
  l10nTitle,
} = useChangelog()

const changelogs = ref([])

function fetchChangelogs() {
  store.commit('spinner/ENABLE')

  const params = {
    'page': page.value,
  }

  fetchApiChangelogs(params)
    .then((response) => {
      changelogs.value = response.data.data?.map((changelog) => ({
        resource: changelog,
      })) ?? []

      // Pagination links
      links.value = response.data.meta?.links ?? []

      // Load last update
      if (changelogs.value.length > 0) {
        fetchChangelog([...changelogs.value].shift()?.resource.id)
      }
    })
    .finally(() => {
      store.commit('spinner/DISABLE')
    })
}

// ---------- LAST CHANGELOG ----------

const lastChangelog = ref(null)
const loading = ref(true)

function fetchChangelog(id) {
  loading.value = true

  fetchApiChangelog(id)
    .then((response) => {
      lastChangelog.value = response.data.data
    })
    .finally(() => {
      loading.value = false
    })
}

// ---------- BREADCRUMBS ----------

const { index } = breadcrumbChangelog()

const breadcrumbs = computed(() => (
  index()
))

// ---------- PAGINATION ----------

const links = ref([])
const page = ref(1)

function handleChangePage(value) {
  page.value = value
  fetchChangelogs()
}

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchChangelogs()
})
</script>
